header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1220px;
    margin: 0 auto;
    height: 80px;
    padding: 1em;
    transform: translateY(-80px);

    @media (min-width: 720px) {
      padding: 1em 3em;
    }

    @media (min-width: 820px) {
      justify-content: space-between;
    }

    @media (min-width: 1266px) {
      padding-inline: 0;
    }

    img {
      max-width: 170px;
      height: auto;
      z-index: 100;
    }

    nav {
      position: fixed;
      top: 0;
      width: calc(100% + 2rem);
      transition: 150ms linear;
      margin-left: -1rem;

      @media (min-width: 720px) {
        margin-left: -3rem;
      }

      @media (min-width: 820px) {
        position: unset;
        min-height: unset;
        backdrop-filter: unset;
        -webkit-backdrop-filter: unset;
        background: unset;
        width: unset;
        align-items: center;
        transform: translateX(0) !important;
      }

      ul {
        text-align: left;
        padding: 1em;
        margin-top: 0;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
        margin-bottom: 0;
        background: #1b1e1f;

        &:first-child {
          border-top: 1px solid rgba(255 255 255 / 0.2);
          border-bottom: 1px solid rgba(255 255 255 / 0.2);
        }

        @media (min-width: 720px) {
          padding: 1em 3em;
        }

        @media (min-width: 820px) {
          display: flex;
          gap: 2.25em;
          margin: 0;
          box-shadow: unset;
          background: unset;
          opacity: unset;
          padding-inline: 0;

          &:first-child {
            border-top: unset;
            border-bottom: unset;
          }
        }

        li {
          a {
            position: relative;
            display: block;
            padding: 0.5em 1em;
            margin-left: -1em;
            width: calc(100% + 1.75em);

            @media (min-width: 820px) {
              width: unset;
              margin-left: unset;
              padding: unset;
              border-bottom: unset;
              background-color: unset;
              overflow: visible;
              padding: 0.5em;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
    button {
      position: absolute;
      right: 0.825em;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      @media (min-width: 820px) {
        display: none;
      }
    }
  }

  .pathContainer {
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  }

  .path {
    background-image: url("../assets/selection.svg");
    background-size: cover;
    z-index: 1;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: trace 0.5s ease-in-out forwards;

    @keyframes trace {
      0% {
        stroke-dashoffset: 868;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
  }

  .pageScrollBar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    background: var(--gradient);
    border-radius: 0 50px 50px 0;
  }
}
