@mixin svgIcon($image) {
  background-image: url($image);
  background-position: 0.5em center;
  background-repeat: no-repeat;
  padding-left: 2.5em;
}

.tour {
  padding: 0 1em;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 6.25em 0 0;

  @media (min-width: 720px) {
    padding: 10em 0 0;
  }

  .titleContainer {
    margin-block: 0 2em;

    & > * {
      margin: 0;
    }

    h1 {
      max-width: 750px;
      font-size: 3rem;

      @media (min-width: 580px) {
        font-size: 4em;
      }
    }

    p {
      font-size: 1.75rem;
      color: var(--clr-primary-light);
      line-height: 1.5;
    }
  }

  .col1 {
    .tourInfo {
      @media (min-width: 980px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6em;
      }

      @media (min-width: 1220px) {
        grid-template-columns: 3fr 2fr;
      }
    }
    .infoContainer {
      max-width: 750px;
      display: flex;
      flex-wrap: wrap;
      gap: 0 1rem;

      p:not(:nth-of-type(5)) {
        margin: 1em 0 0 0;
        border-radius: 6px;
        font-size: 1rem;
        padding: 0.25em 0.75em;
        background-color: #1b1e1f;
        color: var(--clr-primary-light);

        &:nth-of-type(4) {
          grid-area: price;
          background-color: var(--clr-primary-light);
          color: var(--clr-primary-dark);
          font-weight: var(--fw-bold);
        }

        &:nth-of-type(3) {
          @include svgIcon("../assets/clock.svg");
        }

        &:nth-of-type(2) {
          @include svgIcon("../assets/group.svg");
        }

        &:nth-of-type(1) {
          @include svgIcon("../assets/schedule.svg");
        }
      }
    }

    button {
      border: none;
      margin-top: 1.125em;
      padding: 0.5em 0.725em;
    }
  }
}

.inclusions {
  margin-bottom: 3em;

  @media (min-width: 720px) {
    margin-bottom: 6em;
  }

  h3 {
    font-size: 1.25rem;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    gap: 1rem;
    margin-bottom: 2.5rem;

    p {
      font-size: 1rem;
      background-color: #1b1e1f;
      color: var(--clr-primary-light);
      padding: 0.25em 0.75em;
      border-radius: 6px;
      margin: 0;
    }
  }
}

.tourBgImage {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 600px;
  transform: matrix(-1, 0, 0, 1, 0, 0) scale(0.5);
  z-index: -1;

  @media (min-width: 720px) {
    min-height: 100vh;
  }

  @media (min-width: 980px) {
    margin-left: 30%;
  }

  img {
    object-fit: cover;
    height: 100%;
  }

  &::before,
  &::after {
    position: inherit;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
  }

  &::before {
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #141617 100%),
      linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #141617 100%);
    z-index: 1;
  }

  &::after {
    right: 70%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
    animation: moveIn 1000ms linear;

    @keyframes moveIn {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.tourHighlights {
  margin-bottom: 3em;

  @media (min-width: 720px) {
    margin-bottom: 6em;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5em;
    margin-bottom: 1em;

    div {
      img {
        border-radius: 6px 6px 0 0;
        width: 100%;
      }

      p {
        font-size: 1rem;
        line-height: 1;
        border-radius: 0 0 6px 6px;
        margin-top: -8px;
        background-color: #1b1e1f;
        padding: 1em;
        text-align: center;
      }
    }
  }
}
