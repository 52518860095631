.reviewSection {
  padding: 0 1em;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto 6em;
  text-align: center;
  padding: 0;

  @media (min-width: 680px) {
    margin-bottom: 12em;
  }

  .card {
    max-width: 455px;
    text-align: center;
    margin-inline: auto;

    &:nth-of-type(2),
    &:nth-of-type(4) {
      background-color: #1b1e1f;
      border: none;

      @media (max-width: 1440px) {
        border: 1px solid #ffffff10;
        background-color: transparent;
      }
    }

    img {
      width: 120px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50%;
    }

    p:nth-of-type(1) {
      font-weight: var(--fw-normal);
      font-size: 1.25rem;
      line-height: 1.6;
    }

    p:nth-of-type(2) {
      margin-bottom: 0;
    }
  }
}

.arrowContainer {
  display: none;
  position: absolute;
  left: 45%;
  top: -30%;
  transform: scale(0.6);

  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: trace 1.5s ease-in-out forwards;
    z-index: 1;

    @keyframes trace {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        opacity: 1;
        stroke-dashoffset: 0;
      }
    }
  }
}
