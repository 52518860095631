@import "./background.module.scss";

@mixin svgIcon($image) {
  background-image: url($image);
  background-position: 0.5em center;
  background-repeat: no-repeat;
  padding-left: 2.5em;
}

.tourSection {
  position: relative;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 6.25em 0;

  @media (min-width: 720px) {
    padding: 10em 0;
  }

  .span {
    color: var(--clr-accent);
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
  }

  h1 {
    margin-top: 0;
  }

  .toursContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2em;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

// Cards Style

.tourCard {
  position: relative;
  border-radius: 6px;
  margin-bottom: 2em;

  img {
    border-radius: 6px;
    height: 264px;
    width: 100%;
    object-fit: cover;
  }

  .comingSoon {
    position: absolute;
    top: 1em;
    left: 1em;
    color: var(--clr-primary-dark);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    background-color: #ffffff80;
    font-weight: bold;
    padding: 0.25em 1em;
    border-radius: 6px;
  }

  div {
    padding-top: 0.5em;

    h3 {
      margin-block: 0;
      color: var(--clr-primary-light);
    }

    div {
      width: 100%;
      display: grid;
      grid-template-columns: max-content max-content 1fr max-content;
      grid-template-areas: "pax length . price";
      justify-content: space-between;
      gap: 0.725em;
      padding-block: 1em;
      border-bottom: 1px solid #292d2f;

      p {
        border-radius: 6px;
        font-size: 0.9125rem;
        padding: 0.25em 0.75em;
        margin-block: 0;

        &:nth-of-type(1),
        &:nth-of-type(2) {
          background-color: #1b1e1f;
          color: var(--clr-primary-light);
        }

        &:nth-of-type(3) {
          grid-area: price;
          background-color: var(--clr-primary-light);
          color: var(--clr-primary-dark);
          font-weight: var(--fw-bold);
        }

        &:nth-of-type(2) {
          grid-area: length;
          @include svgIcon("../assets/clock.svg");
        }

        &:nth-of-type(1) {
          grid-area: pax;
          @include svgIcon("../assets/group.svg");
        }
      }
    }

    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #ffffff99;
      font-size: 1rem;
      line-height: 2;
      margin-top: 0.825em;
      margin-bottom: 1.5em;
    }

    a {
      box-shadow: none;
    }
  }
}
