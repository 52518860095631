@import "./background.module.scss";

.main {
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding-block: 6.25em;

  @media (min-width: 720px) {
    padding-block: 10em;
  }

  .aboutUs {
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: 780px) {
      flex-direction: row;
      gap: 0 4em;
    }

    h1 {
      grid-area: title;
      margin-top: 0;
      max-width: 814px;

      span {
        color: var(--clr-accent);
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
      }

      @media (min-width: 980px) {
        margin-top: 0;
      }
    }

    img {
      /* grid-area: img; */
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      box-shadow: 0 0 300px rgba(0 0 0 / 1);
    }

    div {
      grid-area: body;

      p {
        max-width: 814px;

        @media (min-width: 780px) {
          &:nth-of-type(1) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
