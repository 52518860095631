@import "./background.module.scss";

.container {
  position: relative;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  padding: 6.25em 0;

  @media (min-width: 720px) {
    padding: 10em 0;
  }

  h1 {
    max-width: 850px;
    margin-top: 0;

    span {
      color: var(--clr-accent);
      clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
    }
  }

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 0.25em;
  }

  p:nth-of-type(1),
  p + p {
    margin-bottom: 2em;

    &:nth-of-type(2) {
      margin-bottom: 0;
    }
  }

  .flexContainer {
    @media (min-width: 720px) {
      display: flex;
      grid-gap: 0 2.25em;
    }

    @media (min-width: 1440px) {
      display: flex;
      grid-gap: 0 6.25em;
    }
  }

  form {
    display: grid;
    grid-template-areas:
      "first last"
      "email email"
      "comment comment"
      "button button";
    gap: 0 1em;
    background-color: var(--clr-primary-light);
    padding: 0 1em 1em;
    border-radius: 6px;
    margin-bottom: 2em;

    @media (min-width: 980px) {
      padding: 1em 2em 2em;
    }

    div:nth-of-type(1) {
      grid-area: first;
    }

    div:nth-of-type(2) {
      grid-area: last;
    }

    div:nth-of-type(3) {
      grid-area: email;
    }

    div:nth-of-type(4) {
      grid-area: comment;
    }

    button {
      grid-area: button;
      margin-top: 1em;

      &:hover::after {
        display: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        color: var(--clr-primary-dark);
        font-weight: var(--fw-bold);
        font-size: 1.125rem;
        margin-left: 1rem;
        transform: translateY(50%);
        background: linear-gradient(180deg, #fff 50%, #f5f5f5 50%);
        width: fit-content;
        padding: 0 6px;
      }

      input,
      textarea {
        font-size: 1rem;
        color: #414547;
        background-color: #f5f5f5;
        border: 1px solid var(--clr-primary-dark);
        border-radius: 6px;
        height: 3.5rem;
        outline: none;
        padding: 0.25em 0.75em;
        width: 100%;
      }

      input:focus,
      textarea:focus {
        border: 1px solid var(--clr-accent);
      }

      textarea {
        min-height: 185px;
        padding: 1em;
      }
    }

    button {
      width: 100%;
      border: none;
      box-shadow: none;
      padding: 1em;

      &:hover {
        background-color: var(--clr-primary-dark);
        color: var(--clr-primary-light);
      }
    }
  }
}

@include backgroundImg("../assets/contact-page-bg-image.jpg");
