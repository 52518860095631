.section {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  max-width: 1220px;
  z-index: -2;
  border-radius: 6px;
  margin-bottom: 6em;

  @media (min-width: 680px) {
    margin-bottom: 12em;
  }

  @media (min-width: 780px) {
    flex-direction: row;
    justify-content: space-between;
    margin-inline: auto;
    align-items: center;
    text-align: left;
    gap: 4em;
  }

  .col1 {
    h2,
    p {
      max-width: 580px;
    }
  }

  .col2 {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
    max-width: 532px;
    aspect-ratio: 1;
    background-image: url("../assets/square-bg-grid.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 2em;

    @media (min-width: 780px) {
      grid-template-columns: repeat(16, 1fr);
      grid-template-rows: repeat(16, 1fr);
      margin-bottom: 0;
      gap: 1em;
      margin-inline: auto 0;
    }

    :nth-child(-n + 2) {
      object-fit: cover;
    }

    img {
      border-radius: 6px;
      width: 100%;
      height: 100%;
      opacity: 1;

      @media (min-width: 780px) {
        max-width: 250px;
      }

      &:nth-of-type(1) {
        grid-column: 4 / -1;
        grid-row: 1 / 5;

        @media (min-width: 780px) {
          grid-column: -1 / 8;
          grid-row: 1 / 10;
        }
      }

      &:nth-of-type(2) {
        grid-column: 1/ 5;
        grid-row: 4/ 8;

        @media (min-width: 780px) {
          grid-column: 1/ 10;
          grid-row: -1 / 8;
        }
      }

      &:nth-of-type(3) {
        width: 80px;
        margin-inline: auto;
        grid-column: 1 / 4;
        grid-row: 1 / 4;
        opacity: 0;

        @media (min-width: 780px) {
          width: 50px;
          grid-column: 1 / 8;
          grid-row: 1 / 8;
        }

        @media (min-width: 1190px) {
          width: 80px;
        }
      }

      &:nth-of-type(4) {
        width: 80px;
        margin-inline: auto;
        transform: rotate(190deg);
        grid-column: 5 / 8;
        grid-row: 5 / 8;
        opacity: 0;

        @media (min-width: 780px) {
          width: 50px;
          grid-column: 9 / 17;
          grid-row: 9 / 17;
        }

        @media (min-width: 1190px) {
          width: 80px;
        }
      }
    }
  }
}
