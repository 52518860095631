.footer {
  padding: 0 1em;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto 2em;
  padding: 0;

  .formContainer {
    background-color: #1b1e1f;
    padding: 2em;
    margin-bottom: 6em;
    border-radius: 6px;

    @media (min-width: 980px) {
      display: grid;
      grid-template-columns: 3fr 2fr;
      align-items: center;
      gap: 2em;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    p {
      max-width: 550px;
      margin-bottom: 0;
      font-size: 1rem;
    }

    form {
      div {
        display: flex;
        flex-direction: column;
        position: relative;

        label {
          color: var(--clr-primary-light);
          font-weight: var(--fw-bold);
          font-size: 1.125rem;
          margin-left: 1rem;
          transform: translateY(50%);
          background: linear-gradient(180deg, #1b1e1f 50%, var(--clr-primary-dark) 50%);
          width: fit-content;
          padding: 0 6px;
        }

        input {
          font-size: 1rem;
          color: var(--clr-primary-light);
          background-color: var(--clr-primary-dark);
          border: 1px solid #292d2f;
          border-radius: 6px;
          height: 64px;
          outline: none;
          padding: 0.25em 0.75em;
        }

        input:focus {
          border: 2px solid var(--clr-accent);
        }

        button {
          display: block;
          width: 100%;
          margin-inline: auto 0;
          border-radius: 4px;
          padding: 0.75em 1.25em;
          border: none;
          transition: 150ms linear;
          cursor: pointer;
          margin-top: 0.5em;
          height: inherit;
          box-shadow: none;

          @media (min-width: 980px) {
            width: fit-content;
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    @media (min-width: 720px) {
      margin-top: 8.125em;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .col1 {
      h2 {
        margin-top: 0;
      }
      .media {
        display: flex;
        gap: 1em;

        a img {
          width: 33px;
        }
      }
    }

    .col2 {
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      grid-gap: 0.5em;

      a {
        color: var(--clr-primary-light);
        width: fit-content;

        &:hover {
          color: var(--clr-accent);
        }
      }
    }
  }
}

// Copyright

.copyright {
  display: block;
  left: 0;
  background: var(--gradient);
  width: calc(100% + 2em);
  margin-left: -1em;
  padding-inline: 1em;

  @media (min-width: 720px) {
    width: calc(100% + 6em);
    margin-left: -3em;
    padding-inline: 3em;
  }

  @media (min-width: 1320px) {
    padding-inline: 0;
  }

  @media (min-width: 1440px) {
    width: calc(100% + 3em);
  }

  .container2 {
    max-width: 1220px;
    width: 100%;
    margin-inline: auto;

    p {
      color: var(--clr-primary-dark);
      font-size: 0.825rem;
      font-weight: var(--fw-bold);
      padding-block: 12px;
      margin: 0;
    }
  }
}
