@import "./background.module.scss";

.heroSection {
  padding: 0 1em;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto 6em;
  display: flex;
  text-align: center;
  padding: 0;

  @media (min-width: 780px) {
    text-align: left;
  }

  .content {
    padding-top: 6.25em;
    display: grid;
    grid-template-areas:
      "title"
      "imgGrid "
      "body"
      "btn";

    @media (min-width: 780px) {
      margin-block: 10em 12em;
    }

    @media (min-width: 980px) {
      grid-template-areas:
        "imgGrid title"
        "imgGrid body"
        "imgGrid btn";
      margin-left: -3.5em;
      gap: 0 3.125em;
    }

    h1 {
      position: relative;
      grid-area: title;
      max-width: 850px;
      margin-bottom: 1.5rem;
      margin-top: 0;
      transform: translateY(0);
      text-align: center;

      @media (min-width: 780px) {
        text-align: left;

        &::before {
          position: absolute;
          content: "";
          background-image: url("../assets/dot-grid-vertical.svg");
          background-size: contain;
          background-repeat: no-repeat;
          left: -40px;
          top: -60px;
          width: 99.68px;
          height: 205.06px;
          z-index: -1;
          opacity: 0.1;
        }
      }

      span {
        color: var(--clr-accent);
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
        display: inline-flex;
        flex-direction: column;

        svg {
          top: 1.25em;
          left: 0;
          opacity: 0;
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: trace 1s ease-out forwards;
          animation-delay: 1.8s;
          clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
          z-index: 1;

          @keyframes trace {
            0% {
              opacity: 0;
              stroke-dashoffset: 868;
            }
            10% {
              opacity: 1;
            }
            100% {
              opacity: 1;
              stroke-dashoffset: 0;
            }
          }
        }
      }
    }

    p {
      grid-area: body;
      margin-block: 0 3em;
      max-width: 785px;
    }

    div {
      grid-area: btn;

      a {
        width: fit-content;
        margin-inline: auto;
        font-size: var(--fs-btn) !important;

        @media (min-width: 720px) {
          margin-inline: unset;
        }

        @media (min-width: 980px) {
          margin-bottom: 4em;
        }

        &:nth-of-type(2) {
          background-color: #ffffff10;
          color: var(--clr-primary-light);
          margin-left: 1rem;

          &:hover {
            background-color: #fff;
            color: var(--clr-primary-dark);
          }
        }
      }
    }
  }
}

@include backgroundImg("../assets/hero-img.jpg");
