.formModal {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: #14161799;
  backdrop-filter: blur(2px);

  .bookingForm {
    position: relative;
    display: grid;
    grid-template-areas:
      "price pax"
      "first last"
      "email email"
      "date date"
      "hotel hotel"
      "total total"
      "notice notice"
      "btn btn";
    background-color: var(--clr-primary-light);
    padding: 0 2em 1em;
    width: 100%;
    border-radius: 6px;
    gap: 0 1em;
    grid-template-rows: repeat(8, min-content);
    height: 100%;
    box-shadow: 0 0 100px rgba(0 0 0 / 0.2);
    padding-top: 3em;
    overflow-y: scroll;

    @media (max-width: 405px) {
      grid-template-areas:
        "price price"
        "pax pax"
        "first last"
        "email email"
        "date date"
        "hotel hotel"
        "total total"
        "notice notice"
        "btn btn";
    }

    @media (min-width: 520px) {
      height: unset;
      grid-template-rows: unset;
      margin-inline: auto;
      padding-top: unset;
      overflow-y: unset;
    }

    @media (min-width: 980px) {
      padding: 1em 2em 2em;
      width: 100%;
    }

    .clodeModalBtn {
      position: absolute;
      background-color: var(--clr-primary-dark);
      border: none;
      border-radius: 50%;
      background: url("../assets/close-modal.svg"), var(--clr-primary-dark);
      background-repeat: no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
      right: 1em;
      top: 1em;
      cursor: pointer;
      border: 2px solid white;

      &:hover {
        background: url("../assets/close-modal.svg"), #1b1e1f;
        background-repeat: no-repeat;
        background-size: contain;
      }

      @media (min-width: 520px) {
        top: -16px;
        right: -16px;
      }
    }

    p {
      position: relative;
      grid-area: price;
      color: var(--clr-primary-dark);
      font-weight: var(--fw-bold);
      font-size: 2rem;
      margin-bottom: 0;
      width: 100%;

      span {
        font-size: 1rem;

        &:nth-of-type(1) {
          top: -10px;
          position: absolute;
          display: block;
          color: var(--clr-accent);
        }

        &:nth-of-type(2) {
          font-weight: var(--fw-normal);
        }
      }
    }

    div:nth-of-type(1) {
      grid-area: pax;
    }
    div:nth-of-type(2) {
      grid-area: first;
    }
    div:nth-of-type(3) {
      grid-area: last;
    }
    div:nth-of-type(4) {
      grid-area: email;
    }
    div:nth-of-type(5) {
      grid-area: date;
    }
    div:nth-of-type(6) {
      grid-area: hotel;
      margin-bottom: 1em;
    }
    div:nth-of-type(7) {
      grid-area: total;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      P {
        margin-top: 0;

        &:nth-of-type(1) {
          font-weight: var(--fw-normal);
          font-size: 1.5rem;
        }

        &:nth-of-type(2) {
          text-align: right;
        }
      }
    }

    .formBtn {
      grid-area: btn;
      margin-top: 1em;
      width: 100%;
      border: none;
      box-shadow: none;
      padding: 1em;

      &:hover {
        background-color: var(--clr-primary-dark);
        color: var(--clr-primary-light);
      }

      &:hover::after {
        display: none;
      }
    }

    .notice {
      grid-area: notice;
      font-size: 0.75rem;
      font-weight: normal;
      font-style: italic;
      margin-top: 0;
      color: red;
      opacity: 0.8;
    }

    div {
      display: flex;
      flex-direction: column;

      label {
        color: var(--clr-primary-dark);
        font-weight: var(--fw-bold);
        font-size: 1.125rem;
        margin-left: 1rem;
        transform: translateY(50%);
        background: linear-gradient(180deg, #fff 50%, #f5f5f5 50%);
        width: fit-content;
        padding: 0 6px;
      }

      input {
        font-size: 1rem;
        color: #414547;
        background-color: #f5f5f5;
        border: 1px solid var(--clr-primary-dark);
        border-radius: 6px;
        height: 3.5rem;
        outline: none;
        padding: 0.25em 0.75em;
        width: 100%;

        &:focus {
          border: 1px solid var(--clr-accent);
        }
      }
    }
  }
}
