*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: "Poppins", sans-serif;
  color: var(--clr-primary-light);
  background-color: var(--clr-primary-dark);
  margin: 0;
  padding: 0 1em;
}

@media (min-width: 720px) {
  body {
    padding: 0 3em;
  }
}

@media (min-width: 1440px) {
  body {
    padding: 0;
  }
}

:root {
  /* Font Sizes */
  --fs-h1: 2.375rem;
  --fs-h2: 1.5rem;
  --fs-h3: 1.25rem;
  --fs-h4: 1.25rem;
  --fs-body: 1rem;
  --fs-btn: 1.125rem;

  /* Font Weights */

  --fw-light: 300;
  --fw-normal: 400;
  --fw-bold: 700;
  --fw-black: 900;

  /* Colors */
  --clr-primary-dark: #141617;
  --clr-primary-light: #fff;
  --clr-accent: #f28e34;

  /* Gradient */
  --gradient: linear-gradient(95.98deg, #f28e34 0%, #bd6414 97.67%);
}

@media (min-width: 720px) {
  :root {
    --fs-h1: 3.625rem;
    --fs-h2: 1.5rem;
    --fs-body: 1rem;
    --fs-btn: 1.25rem;
  }
}

@media (min-width: 1220px) {
  :root {
    --fs-h2: 2.25rem;
    --fs-body: 1.125rem;
    --fs-btn: 1.5rem;
  }
}

@media (min-width: 1440px) {
  :root {
    --fs-h1: 6.125rem;
  }
}

img {
  max-width: 100%;
}

/* Typgraphy */

h1 {
  position: relative;
  font-size: var(--fs-h1);
  font-weight: var(--fw-black);
  line-height: 1.1;
  text-transform: uppercase;
  text-align: left;
}

h2 {
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
}

h3,
h4 {
  font-size: var(--fs-h4);
  font-weight: var(--fw-bold);
}

p {
  font-size: var(--fs-body);
  line-height: 1.8;
  font-weight: var(--fw-light);
  color: #ffffff99;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--clr-primary-light);
}

.title-highlight {
  color: var(--clr-accent);
}

/* Button */

.btn {
  padding: 0.4em 1em;
  border-radius: 4px;
  height: fit-content;
  cursor: pointer;
  font-weight: var(--fw-medium);
  font-size: 1rem;
}

.btn:hover {
  background: var(--clr-primary-light);
  color: var(--clr-primary-dark);
}

.primary-btn {
  position: relative;
  color: var(--clr-primary-light);
  background-image: var(--gradient);
  box-shadow: 8px 8px 13px rgba(0, 0, 0, 0.2);
}

/* Back to top Button */
.backToTopBtn {
  position: fixed;
  background-color: #ffffff10;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  right: 0;
  bottom: 10%;
  border: none;
  outline: none;
  border-radius: 6px 0 0 6px;
  background-image: url("./components/assets/arrow-up.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(4px);
  --webkit--backdrop-filter: blur(4px);
  cursor: pointer;
  z-index: 100;
}

/* Swiper */
.swiper-pagination {
  bottom: -1em;
  position: unset;
  margin-top: 2em;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--clr-accent);
  transform: scale(1.5);
}

.swiper-pagination-bullet {
  background-color: var(--clr-primary-light);
  transition: 250ms;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--clr-accent) !important;
  margin: 0 2em;
  background-color: #ffffff10;
  padding: 2.5em;
  border-radius: 50%;
  transform: translateY(-50%);
}
