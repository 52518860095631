.EasySection {
  position: relative;
  padding: 0 1em;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto 6em;
  text-align: center;
  padding: 0;

  @media (min-width: 720px) {
    margin-bottom: 12em;
  }

  h2 {
    margin-bottom: 2.5em;
  }

  .container {
    display: grid;
    gap: 2em;

    @media (min-width: 563px) {
      grid-template-areas:
        "select select"
        "send location";
    }

    @media (min-width: 910px) {
      grid-template-areas: "select send location";
    }

    @media (min-width: 1100px) {
      gap: 0;
      grid-template-columns: repeat(5, 1fr);
      grid-template-areas: "select divider1 send divider2 location";
    }

    div {
      max-width: 300px;
      margin-inline: auto;

      & > * {
        margin-inline: auto;
      }

      &:nth-of-type(1) {
        @media (min-width: 563px) {
          grid-area: select;
        }
      }
      &:nth-of-type(2) {
        @media (min-width: 563px) {
          grid-area: send;
        }
      }
      &:nth-of-type(3) {
        @media (min-width: 563px) {
          grid-area: location;
        }
      }
    }

    hr {
      position: relative;
      display: none;
      border: none;
      width: 10px;

      @media (min-width: 1100px) {
        display: block;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
      }

      &::before {
        width: 1px;
        height: 100%;
        background-color: #292d2f;
      }

      &::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: var(--clr-accent);
      }

      &:nth-of-type(1) {
        grid-area: divider1;
      }

      &:nth-of-type(2) {
        grid-area: divider2;
      }
    }
  }
}
