.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1220px;
  z-index: -2;
  border-radius: 6px;
  margin-bottom: 6em;

  @media (min-width: 680px) {
    margin-bottom: 12em;
  }

  @media (min-width: 780px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    gap: 4em;
    margin-inline: auto;
  }

  .col1 {
    h2,
    p {
      max-width: 580px;
    }
  }

  .col2 {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.5fr 3fr 0.5fr;
    gap: 1em;
    max-width: 532px;
    aspect-ratio: 1;
    background-image: url("../assets/square-bg-grid.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    margin-bottom: 2em;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      bottom: 0;
      left: 50%;
      right: 0;
      width: 50%;
      height: 80%;
      transform: translate(-50%, -50%);
      border-radius: 6px;
      border: 3px solid var(--clr-accent);
      z-index: -1;
    }

    @media (min-width: 780px) {
      margin-bottom: 0;
    }

    img {
      position: relative;
      border-radius: 6px;
      width: 100%;
      opacity: 1;

      @media (min-width: 780px) {
        max-width: 250px;
      }

      &:nth-of-type(1) {
        grid-row: 2 / 4;
        grid-column: 2 / 3;
      }

      &:nth-of-type(2) {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
      }
    }
  }
}
